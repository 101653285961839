import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { FaFacebook, FaTwitter } from "react-icons/fa"

const Footer = () => {
  return (
    <footer className="bg-info">
      <Container className="footer mt-4">
        <Row>
          <Col>
            <p className="pt-5">Costa Dental © 2020</p>
          </Col>
          <Col className="mt-4">
            <div className="float-right">
              <a
                href="https://www.facebook.com/CostaDentalMX/"
                target="_blank"
                rel="noopener noreferrer"
                className="px-3"
              >
                <FaFacebook className="mr-2 mt-3" size={40} />
              </a>
              <a
                href="https://twitter.com/CostaDentalMX"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter className="mr-2 mt-3" size={40} />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
