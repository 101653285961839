import React, { useEffect, useState, useRef } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { Navbar, Nav, Container } from "react-bootstrap"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(width: 129, height: 91) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
    }
  `)

  const [navBackground, setNavBackground] = useState(false)
  const navRef = useRef()
  navRef.current = navBackground
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 100
      if (navRef.current !== show) {
        setNavBackground(show)
      }
    }
    document.addEventListener("scroll", handleScroll)
    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <Navbar expand="md" bg="transparent" id="site-navbar">
      <Container>
        <Link to="/">
          <Navbar.Brand>
            <Img fixed={data.file.childImageSharp.fixed} />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Link to="/">
              <Nav.Link as="span" className="mr-4">
                INICIO
              </Nav.Link>
            </Link>
            <Link to="/#services">
              <Nav.Link as="span" className="mr-4">
                SERVICIOS
              </Nav.Link>
            </Link>
            <Link to="/#howtoget">
              <Nav.Link as="span" className="mr-4">
                COMO LLEGAR
              </Nav.Link>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header
