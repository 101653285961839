import React from "react"
import Header from "./header"
import Footer from "./footer"
import CustomerChat from "./CustomerChat"

const Layout = props => {
  return (
    <div>
      <div>
        <Header />
        {props.children}
      </div>
      <Footer />
      <CustomerChat />
    </div>
  )
}

export default Layout
